@import "../abstracts/variables.scss";

.epar{
  &__grid__buttons {
  padding: 10%;
  }
}


.main-container {
  height: 100%;
  width: 100%;
  position: fixed;
  background: transparent !important;
}

.home-link {
  float: right;
}

.facility-admin-content {
  margin-left: 10pt;
}

.header-tools {
  float: right;
}


.epar{
  &__dashboard {
    &--container {
      background: $eparViewBackgroundColor;
      margin: 0px;
    }

    &--card {
      padding: 5px;
    }
  }
}

.epar__chart__container {
  height: 300px;
  width: 100%;
}

.epar__admin__container {
  height: 80px;
  width: 100%;
  a {
    color: #218fa9;
  }
}

.epar__ou__selector {
  ant-select
  .ant-select-selection{
    margin-left: 24px;
  }

  .ant-select-arrow:before{
    content: "" !important
  }
}


.epar__ou__selector:before{
  content: "";
  background: url('/assets/icons/building.png') no-repeat;
  width: 20px;
  height: 40px;
  margin-top: 2px;
  float: left;
  background-size: contain;    
  background-position-y: 20px;
}

.epar__ou__selector__desktop {
  .ant-select-selection{
    border: none !important;
  }
}

.epar__ou__selector__mobile {  
  .ant-select-selection{
    background: #002140;
    color: #fff;  
  }
}